<template>
    <v-menu
        v-model="open"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
        :min-width="400"
        :max-width="400"
    >
        <template v-slot:activator="{ on: onMenu, attrs: menuAttrs }">
            <v-badge
                :content="attachments.length"
                :value="attachments.length"
                color="green"
                overlap
            >
                <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip, attrs: tooltipAttrs }">
                        <v-btn
                            icon
                            small
                            v-bind="{...tooltipAttrs, ...menuAttrs}"
                            v-on="{...onTooltip, ...onMenu}"
                        >
                            <v-icon small>fal fa-paperclip</v-icon>
                        </v-btn>
                    </template>
                    <span>Attachments</span>
                </v-tooltip>
            </v-badge>
        </template>
        <v-card>
            <v-card-title class="tw-justify-between">
                <div>Attachments</div>
            </v-card-title>
            <v-card-text>
                <div v-if="attachments.length > 0" >
                    <div class="tw-flex tw-items-center">
                        <v-chip
                            v-for="attachment in attachments"
                            :key="attachment.id"
                            :href="attachment.download_url"
                        >
                            <v-img
                                :src="attachment.download_url"
                            />
                            <div>{{ attachment.file_name }}</div>
                        </v-chip>
                    </div>
                </div>
                <div v-else>No attachments available</div>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: 'ticket-attachments-button',
    props: {
        attachments: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            open: false,
        }
    },
}
</script>
